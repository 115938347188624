import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Layer Zero Labs</h1>
      <h3>Freelance Development | Full Stack | Front End</h3>
      <h3><a href="mailto:ben@layerzerolabs.com">CONTACT</a></h3>
    </div>
  );
}

export default App;
